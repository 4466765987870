.App {
  min-height: 100vh;
}

.textbox {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.textbox:focus {
  outline: none;
  border-color: #6875f5;
}

.btn {
  background-color: #6875f5;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}

/* Survey: Hide powered by badge */
a.delighted-web-survey-powered {
  opacity: 0 !important;
  pointer-events: none !important;
}

/*
 * Remove errant Google Maps container which blocks click events on Pins,
 * specifically impacting ClaimView Spotlight.
 * Ref: https://stackoverflow.com/a/69031991
 */
.gm-style-moc {
  height: unset !important;
}
